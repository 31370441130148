import { AxiosError, AxiosInstance } from 'axios';
import { authApi } from 'config/api';
import { Incident, Mark, PICTaskDisposition, Task } from 'types';
import { scaleMarkForApi } from 'utils/incident';

interface CompleteTaskParameters {
  /** The id of the task to complete */
  taskId: string;
  /** The disposition of the task */
  disposition: PICTaskDisposition;
  /** The mark for a task that will be dispositioned as fire */
  mark?: Mark;
  /** The id of the duplicate incident for a task to be dispositioned as duplicate */
  duplicateId?: Incident['id'];
  /** The id of the station to associate the fire with */
  stationId?: Task['ecId'];
}

interface GetCompleteBodyParameters {
  /** The disposition of the task */
  disposition: PICTaskDisposition;
  /** The mark for a task that will be dispositioned as fire */
  mark?: Mark;
  /** The id of the duplicate incident for a task to be dispositioned as duplicate */
  duplicateId?: Incident['id'];
  /** The id of the station to associate the fire with */
  stationId?: Task['ecId'];
}

export default class TaskApi {
  api: AxiosInstance;
  isFetchingTask: boolean;

  constructor() {
    this.init();
    this.isFetchingTask = false;
  }

  async init(): Promise<void> {
    const api: AxiosInstance = await authApi();
    this.api = api;
  }

  setAxiosInstanceApi(axiosInstance: AxiosInstance): void {
    this.api = axiosInstance;
  }

  /**
   * @description This calls the /task:queue API which either:
   * - Assigns a user a new task
   * - Returns the user's currently assigned task
   * Appends 'X-Test-Key' header for testing if localStorage.PWF_TEST_KEY is set
   * Prevents `/deque` from being called multiple times simultaneously
   * - The use case for that is in Full
   */
  async apiFetchTask(): Promise<Task> {
    try {
      if (this.isFetchingTask) {
        return null;
      }

      this.isFetchingTask = true;

      const headers = localStorage.PWF_TEST_KEY ? { 'X-Test-Key': localStorage.PWF_TEST_KEY } : {};
      const { data }: { data: Task } = await this.api.post(`/task:dequeue`, null, {
        headers,
      });

      this.isFetchingTask = false;

      return data;
    } catch (e) {
      console.error('[Pano] API Error Task', e);
      this.isFetchingTask = false;

      return null;
    }
  }

  /**
   * This calls the /task/${taskId}:extend API which
   * - Extends the life of the task
   */
  async apiExtendTask(taskId: string): Promise<number | 'expired' | 'error'> {
    try {
      const {
        data,
      }: {
        data: {
          newExpirationTime: string;
        };
      } = await this.api.post(`/task/${taskId}:extend`);

      return Number(data.newExpirationTime);
    } catch (err) {
      if ((err as AxiosError)?.response?.status === 410) {
        console.error('[Pano] API Error Task', err);

        return 'expired';
      }

      return 'error';
    }
  }

  getCompleteBody({ mark, disposition, duplicateId, stationId }: GetCompleteBodyParameters) {
    if (disposition === PICTaskDisposition.needsReview) {
      const { startTime, ...restMark } = mark;
      const scaledMark = scaleMarkForApi(restMark);

      return {
        disposition,
        incident: {
          cameras: [
            {
              id: stationId,
              mark: {
                ...scaledMark,
              },
            },
          ],
          startTime,
          source: 'mark',
        },
      };
    } else if (disposition === PICTaskDisposition.duplicate) {
      return {
        disposition,
        incidentId: duplicateId,
      };
    }

    return {
      disposition,
    };
  }

  /**
   * Calls the API to complete the task
   */
  async apiCompleteTask({
    taskId,
    mark,
    disposition,
    duplicateId,
    stationId,
  }: CompleteTaskParameters): Promise<string> {
    try {
      const { data } = await this.api.post(
        `/task/${taskId}:complete`,
        this.getCompleteBody({
          mark,
          disposition,
          duplicateId,
          stationId,
        }),
      );

      return data;
    } catch (err) {
      if ((err as AxiosError)?.response?.status === 410) {
        return 'expired';
      }
      throw new Error(JSON.stringify(err));
    }
  }
}
