import { rsfFeatureFlags } from 'data';
import { FeatureFlagsApis } from 'data/proxyApi';
import { useRecoilState } from 'recoil';
import { FeatureFlagEvaluation, FeatureFlagValue } from 'types';

interface Returned {
  /**
   * Evaluate a boolean feature flag for the currently authenticated user context.
   * @param flagKey The key of the flag to evaluate.
   * @param defaultValue The default value to return in case the flag cannot be evaluated (e.g. it does not exist).
   * @returns the evaluated boolean value, or the `defaultValue` if the flag could not be evaluated.
   */
  evaluateBooleanFlag: (flagKey: string, defaultValue: boolean) => Promise<boolean>;

  /**
   * Evaluate a string feature flag for the currently authenticated user context.
   * @param flagKey The key of the flag to evaluate.
   * @param defaultValue The default value to return in case the flag cannot be evaluated (e.g. it does not exist).
   * @returns the evaluated string value, or the `defaultValue` if the flag could not be evaluated.
   */
  evaluateStringFlag: (flagKey: string, defaultValue: string) => Promise<string>;

  /**
   * Evaluate a number feature flag for the currently authenticated user context.
   * @param flagKey The key of the flag to evaluate.
   * @param defaultValue The default value to return in case the flag cannot be evaluated (e.g. it does not exist).
   * @returns the evaluated number value, or the `defaultValue` if the flag could not be evaluated.
   */
  evaluateNumberFlag: (flagKey: string, defaultValue: number) => Promise<number>;
}

export default function useFeatureFlags(): Returned {
  const [featureFlags, setFeatureFlags] = useRecoilState(rsfFeatureFlags);
  const evaluateFlag = async <T extends FeatureFlagValue>(
    flagKey: string,
    getFlagEvaluation: () => Promise<FeatureFlagEvaluation<T>>,
  ) => {
    let evalulation = featureFlags[flagKey];
    if (evalulation == null) {
      evalulation = await getFlagEvaluation();
      setFeatureFlags({
        ...featureFlags,
        [flagKey]: evalulation,
      });
    }

    return evalulation.value as T;
  };

  const evaluateBooleanFlag = async (flagKey: string, defaultValue: boolean = false) => {
    return evaluateFlag(flagKey, () => FeatureFlagsApis.apiGetBooleanFeatureFlagEvaluation(flagKey, defaultValue));
  };

  const evaluateStringFlag = async (flagKey: string, defaultValue: string = null) => {
    return evaluateFlag(flagKey, () => FeatureFlagsApis.apiGetStringFeatureFlagEvaluation(flagKey, defaultValue));
  };

  const evaluateNumberFlag = async (flagKey: string, defaultValue: number = 0) => {
    return evaluateFlag(flagKey, () => FeatureFlagsApis.apiGetNumberFeatureFlagEvaluation(flagKey, defaultValue));
  };

  return {
    evaluateBooleanFlag,
    evaluateStringFlag,
    evaluateNumberFlag,
  };
}
