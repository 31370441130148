import { SECRET_ANONYMOUS } from 'config/base';
import { atom, RecoilState, RecoilValueReadOnly, selector } from 'recoil';
import { PanoUser } from 'types';
import { accountMfaEnabled } from 'utils/auth';

import { rsaUser } from '../user/user';

/**
 * Whether the user is logining or registered but not setup MFA (not registering).
 */
export const rsaIsPendingMfaSetup: RecoilValueReadOnly<boolean> = selector<boolean>({
  key: 'rsaIsPendingMfaSetup',
  get: ({ get }) => {
    const user: PanoUser = get(rsaUser);

    return (
      !!user && !user?.bearer && accountMfaEnabled(user) && !user?.phoneMfa && user?.firstName !== SECRET_ANONYMOUS
    );
  },
});

/**
 * Whether 2FA is enabled for the current user.
 */
export const rsaMfaEnabled: RecoilValueReadOnly<boolean> = selector<boolean>({
  key: 'rsaMfaEnabled',
  get: ({ get }) => {
    const user: PanoUser = get(rsaUser);

    return accountMfaEnabled(user);
  },
});

export const rsaMfaModalOpen: RecoilState<boolean> = atom<boolean>({
  key: 'rsaMfaModalOpen',
  default: false,
});
